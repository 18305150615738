$base-font-size: 16px;

// Colors
$color-gray-0: #ebebeb;
$color-gray-1: #707070;
$color-gray-2: #555555;
$color-gray-3: #383838;

// Semantic colors
$color-light: #ffffff;
$color-dark: #000000;

$color-main: #5369e5;
$color-main-light: #4070e4;
$color-main-dark: #001475;
$color-main-gray: #98a4ae;

$color-secondary: #12b295;
$color-text: #000839;
$color-text-secondary: #59bec9;

$color-warning: #ff0000;

$color-background-dark: #202b69;
$color-background: #fafafa;

// Sizes
$size-indent-around: 50px;
$size-height-header: 82px;

// Z-index
$z-default: 1;
$z-minus: -1;
$z-content: 10;
$z-header: 50;

// Transitions timing functions
$timing-default: cubic-bezier(0.165, 0.84, 0.44, 1);
$timing-in: $timing-default;
$timing-out: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$timing-layer: $timing-out;
