@import "assets/styles/_variables";

@function parseInt($n) {
  @return $n / ($n * 0 + 1);
}

// example: margin: rem(35px auto 2px 18px)
@function rem($px) {
  $font-size-base: parseInt($base-font-size);
  $i: "";
  @each $p in $px {
    @if $p != "auto" {
      $p: (parseInt($p) / $font-size-base) + rem;
    }
    $i: #{$i} #{$p};
  }

  @return $i;
}

@function font-clamp($px) {
  $font-size-base: parseInt($base-font-size);

  $max-font-size: 16 / $font-size-base;
  $min-font-size: 12 / $font-size-base;
  $max-width: 1440 / $font-size-base;
  $min-width: 640 / $font-size-base;

  $slope: ($max-font-size - $min-font-size) / ($max-width - $min-width);
  $y-axis-intersection: -$min-width * $slope + $min-font-size;

  $i: (parseInt($px) / $font-size-base) + rem;
  $slope-percent: ($slope * 100) + vw;

  @return clamp(
    $min-font-size * #{$i},
    $y-axis-intersection * #{$i} + $slope-percent,
    $max-font-size * #{$i}
  );
}

/// Pure css support for min() and max() functions
@function max($numbers...) {
  @return m#{a}x(#{$numbers});
}

@function min($numbers...) {
  @return m#{i}n(#{$numbers});
}

@mixin transition(
  $property: all,
  $duration: 0.24s,
  $delay: 0s,
  $timing-function: cubic-bezier(0.165, 0.84, 0.44, 1)
) {
  transition: $property $duration $timing-function $delay;
}

// Font Face
@mixin font-face(
  $name,
  $path,
  $weight: null,
  $style: null,
  $exts: woff2 woff ttf svg
) {
  $src: null;

  $extmods: (
    eot: "?",
    svg: "#" + str-replace($name, " ", "_"),
  );

  $formats: (
    otf: "opentype",
    ttf: "truetype",
  );

  @each $ext in $exts {
    $extmod: if(
      map-has-key($extmods, $ext),
      $ext + map-get($extmods, $ext),
      $ext
    );
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append(
      $src,
      url(quote($path + "." + $extmod)) format(quote($format)),
      comma
    );
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

// Media queries
@mixin media($number, $direction: "below") {
  @if $direction == "below" {
    @media screen and (max-width: #{$number}px) {
      @content;
    }
  } @else if $direction == "above" {
    @media screen and (min-width: #{$number + 1}px) {
      @content;
    }
  }
}

%before {
  position: relative;
  z-index: 1;

  &:before {
    content: "";
    position: absolute;
    z-index: 2;
  }
}

%after {
  position: relative;
  z-index: 2;

  &:after {
    content: "";
    position: absolute;
    z-index: 3;
  }
}

%clear-button {
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  font-family: inherit;

  &:focus {
    outline-color: inherit;
  }
}

%hotspot {
  width: rem(20px);
  height: rem(20px);
  background-color: $color-main;
  color: $color-light;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  @extend %before;

  &:before {
    background-color: $color-main-dark;
    width: rem(10px);
    height: rem(10px);
    border-radius: 100%;
  }
}
