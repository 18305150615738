@import "_fonts";
@import "_mixins";
@import "_variables";
@import "_transitions";

* {
  padding: 0;
  margin: 0;
}

html {
  font-size: $base-font-size;
  line-height: 1.1875;
  font-family: Matter, Helvetica, Arial, sans-serif;

  @include media(1440) {
    font-size: 14px;
  }

  @include media(1160) {
    font-size: 13px;
  }

  @include media(1050) {
    font-size: 12px;
  }

  @include media(768) {
    font-size: 11px;
  }

  @include media(640) {
    font-size: 10px;
  }
}

body {
  background-color: $color-background-dark;
  color: $color-text;
  font-size: font-clamp($base-font-size);
  overscroll-behavior: none;
}

#root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
}

.video-wrapper {
  position: relative;

  img.poster {
    width: 100%;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
  }
}

video {
  width: 100%;
}

.content-container {
  max-width: rem(1640px);
  margin: 0 auto;
  padding: rem(120px $size-indent-around 80px);
}

.button {
  @extend %clear-button;
  color: $color-light;
  background: $color-secondary;
  padding: rem(22px 74px);
  border-radius: rem(4px);
  cursor: pointer;
  display: inline-flex;
  max-height: rem(400px);
  font-size: font-clamp(24px);
  font-weight: 500;
  text-transform: uppercase;
  align-self: flex-start;
  justify-content: center;
  @include transition();

  &:focus,
  &:hover {
    opacity: 0.85 !important;
    transition-delay: 0s;
    transform: translate(0, -2px);
  }

  &:focus {
    font-size: font-clamp(26px);
  }

  &--secondary {
    background: $color-main;
  }
}

button:focus {
  outline: 0;
}

img {
  user-select: none;
  vertical-align: middle;
}

video,
img {
  vertical-align: middle;
}

.image-sequence-img {
  width: 100%;
}

.window-width {
  position: relative;
  margin: 0px auto;
  width: 100vw;
  height: 100%;
  bottom: 0;
  top: 0;
  left: 0;
}

.block {
  position: relative;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
}

.content {
  flex: 1;
  overflow: hidden;
  position: relative;

  &.cut {
    overflow: hidden;
  }

  & > :not(.block--custom) * {
    user-select: none;
  }
}

.previous {
  position: absolute;
  width: 100%;
  top: 0px;
  z-index: 0;
}

.hide {
  display: none !important;
}

.transparent {
  position: absolute !important;
  left: 0;
  top: 0;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
}

.next {
  z-index: $z-minus;
  opacity: 1;
  visibility: visible;
}

.active {
  z-index: $z-default + 1;
  //position: absolute;
}

.intro {
  position: absolute;
  top: 35%;
  width: 100%;
  text-align: center;
  color: $color-light;
  text-transform: uppercase;

  .fade {
    opacity: 0;
    transform: translate(0px, -20px);
    transition: all 0.75s cubic-bezier(0.165, 0.84, 0.44, 1);

    .visible & {
      opacity: 1;
      transform: translate(0px, 0px);
    }
  }

  &__h1 {
    font-size: font-clamp(32px);
    color: $color-secondary;
    margin-bottom: 20px;

    transition-delay: 1s !important;
  }

  &__h2 {
    font-size: font-clamp(82px);
    transition-delay: 1.25s !important;
  }

  &__h3 {
    font-size: font-clamp(16px);
    margin-top: 10%;
    transition-delay: 1.4s !important;
  }
}
