@import "assets/styles/_mixins";
@import "assets/styles/_variables";

.logo-wrapper {
  position: fixed;
  top: 0;
  left: 0;

  z-index: 50;

  padding-left: rem($size-indent-around);
}

.intro-root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.intro-wrapper {
  position: fixed;
  z-index: 49;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  transition: opacity 0.45s ease-in;

  &.fade {
    opacity: 0;
  }
}
