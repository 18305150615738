:root {
  --delay-term: 0;
}

.swipe {
  span,
  b {
    display: inline-block;
  }
}

.anim01 .swipe,
.layerswipe {
  & > * {
    clip-path: inset(100% 0% 0% 0%);
    transform: translate(0, 50px);
    opacity: 1;
  }
}

.anim02 .swipe > * {
  clip-path: inset(100% 0% 0% 0%);
  transform: translate(200px, 0);
  opacity: 0;
}

.layerswipe {
  transform: translate(0, 100%);
}

.active,
.active-animation {
  .anim01.in {
    .swipe > * {
      animation: clip01swipe-in 0.7s $timing-in forwards;
    }
  }

  .anim02.in {
    .swipe > * {
      animation: clip02swipe-in 0.7s $timing-in forwards;
    }
  }

  .layerswipe {
    &.in {
      animation: layerswipe-in 0.7s $timing-out forwards;

      * {
        animation: clip01swipe-in 0.7s $timing-in forwards;
      }
    }
  }
}

.exiting + .active,
.active-animation {
  .anim01,
  .anim02 {
    &.out {
      .swipe > * {
        clip-path: inset(0% 0% 0% 0%);
        transform: translate(0, 0);
        opacity: 1;
        animation: clip01swipe-out 0.7s $timing-out forwards;
      }

      .fade > * {
        opacity: 1;
        animation-delay: 0s;
        animation: clip01fade-out 0.7s $timing-out forwards;
      }
    }
  }

  .layerswipe.out {
    animation: layerswipe-out 0.7s $timing-out forwards;

    * {
      clip-path: inset(0% 0% 0% 0%);
      transform: translate(0, 0);
      opacity: 1;
      animation: clip01swipe-out 0.7s $timing-out forwards;
    }
  }
}

.anim01,
.anim02 {
  .fade > * {
    opacity: 0;
  }
}
.active,
.active-animation {
  .anim01.in {
    .fade > * {
      animation: clip01fade-in 0.7s $timing-out forwards;
    }
  }
}

.active,
.active-animation {
  .anim02.in {
    .fade > * {
      animation: clip02fade-in 0.7s $timing-in forwards;
    }
  }
}

@for $item from 1 through 6 {
  .in .swipe > *:nth-child(#{$item}) {
    animation-delay: calc(
      #{$item * 0.05}s + var(--delay-term) * 0.7s
    ) !important;
  }

  .out .swipe > *:nth-child(#{$item}) {
    @if $item == 1 {
      animation-delay: 0s !important;
    } @else {
      animation-delay: calc(
        #{$item * 0.05}s + var(--delay-term) * 0.7s
      ) !important;
    }
  }

  .fade > *:nth-child(#{$item}) {
    animation-delay: calc(
      #{$item * 0.1 + 0.2}s + var(--delay-term) * 0.7s
    ) !important;
  }

  .layerswipe *:nth-child(#{$item}) {
    animation-delay: calc(
      #{$item * 0.25 + 0.05}s + var(--delay-term) * 0.7s
    ) !important;
  }
}

.anim-delay--05 {
  --delay-term: 0.5;
}

.anim-delay--065 {
  --delay-term: 0.65;
}

.anim-delay--085 {
  --delay-term: 0.85;
}

.anim-delay--1 {
  --delay-term: 1;
}

@keyframes clip01swipe-in {
  0% {
    clip-path: inset(100% 0% 0% 0%);
    transform: translate(0, 50px);
    opacity: 1;
  }
  75% {
    clip-path: inset(0% 0% 0% 0%);
  }
  100% {
    clip-path: inset(0% 0% 0% 0%);
    transform: translate(0, 0px);
    opacity: 1;
  }
}
@keyframes clip01swipe-out {
  0% {
    clip-path: inset(0% 0% 0% 0%);
    transform: translate(0, 0px);
    opacity: 1;
  }
  100% {
    clip-path: inset(0% 0% 0% 0%);
    transform: translate(min(-15vw, -100%), 0);
    opacity: 0;
  }
}
@keyframes clip02swipe-in {
  0% {
    clip-path: inset(100% 0% 0% 0%);
    transform: translate(200px, 0);
    opacity: 0;
  }
  100% {
    clip-path: inset(0% 0% 0% 0%);
    transform: translate(0, 0px);
    opacity: 1;
  }
}

@keyframes clip01fade-in {
  0% {
    opacity: 0;
    transform: translate(0, 0px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0px);
  }
}
@keyframes clip01fade-out {
  0% {
    opacity: 1;
    transform: translate(0, 0px);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translate(min(-15vw, -100%), 0);
  }
}
@keyframes clip02fade-in {
  0% {
    opacity: 0;
    transform: translate(200px, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0px);
  }
}

@keyframes layerswipe-in {
  0% {
    transform: translate(0, 100%);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes layerswipe-out {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, -100%);
  }
}

.transition--linear {
  transition-timing-function: linear;
}

.bottom-block-transition {
  &.entered {
    top: 0%;
    transition: all 450ms ease-in-out 0s;
  }

  &.entering {
    top: 100%;
  }

  &.exited,
  &.exiting {
    top: 0px;
  }
}
