@import "_mixins";

@include font-face(
  Matter,
  "../fonts/mattertrial-regular-webfont",
  400,
  normal,
  woff2 woff ttf
);
@include font-face(
  Matter,
  "../fonts/mattertrial-medium-webfont",
  500,
  normal,
  woff2 woff ttf
);
@include font-face(
  Matter,
  "../fonts/mattertrial-semibold-webfont",
  600,
  normal,
  woff2 woff ttf
);
@include font-face(
  Matter,
  "../fonts/mattertrial-bold-webfont",
  700,
  normal,
  woff2 woff ttf
);
@include font-face(
  Matter,
  "../fonts/mattertrial-heavy-webfont",
  900,
  normal,
  woff2 woff ttf
);
